<template>
  <!-- <div class="container">
    <div class="grid grid-nogutter surface-section text-800">
      <div class="col-12 md:col-6 overflow-hidden">
        <img
          src="../assets/img/shop2.jpg"
          alt="Image"
          class="md:ml-auto block"
          style="width: 100%"
        />
      </div>
      <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center">
        <section>
          <div class="text-6xl text-primary font-bold mb-3">
            NBA ONE STOP SHOP
          </div>
          <h2 class="text-primary">"ร้านเดียวครบ จบทุกบริการ"</h2>
          <div class="grid">
            <div class="col-12">
              <div class="field">
                <label>ชื่อผู้ใช้งาน (USERNAME) :</label>
                <InputText
                  v-model="username"
                  type="text"
                  placeholder="ชื่อผู้ใช้งาน"
                  class="w-full font"
                  @keyup.enter="checklogin()"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="field">
                <label>รหัสผ่าน (PASSWORD) :</label>
                <InputText
                  v-model="password"
                  type="password"
                  placeholder="รหัสผ่าน"
                  class="w-full font"
                  @keyup.enter="checklogin()"
                />
              </div>
            </div>
          </div>

          <Button
            label="เข้าสู่ระบบ"
            class="w-full"
            @click="checklogin()"
            :loading="isLoading"
          ></Button>
        </section>
      </div>
    </div>
  </div> -->



  <div class="container ">
    <div class="modal ">
      <section>
        <div class="grid  border-round-3xl border-3   px-2 m-5 mt-7  bg-login text-900b opacity-90"
          style="border: 2px solid #A31ACB;">
          <div class="col-12 ">
            <div class="field">
              <label style="font-size: 30px; font-weight: 900; color: #5a0a8f; text-shadow: #ffffff 1px 0 10px;"
                class="mb-4">
                <svg width="23" height="23" class="" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                  style="transform: translateY(3px); filter: drop-shadow(1px 1px 10px #fff);">
                  <g fill="none" stroke="#5a0a8f" stroke-linecap="round" stroke-linejoin="round">
                    <circle cx="6.44" cy="11.33" r="2.17" stroke-width="2" />
                    <path d="m8 9.8l3.86-3.86a.36.36 0 0 1 .51 0l1.13 1.15m-3.05.28l1.02 1.02" stroke-width="2" />
                    <path d="M2 12.5h-.5a1 1 0 0 1-1-1v-10a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1V4m-13-.5h13" stroke-width="2" />
                  </g>
                </svg>
                LOGIN</label>
              <p
                class="pt-0 pr-2 pb-0 pl-2 -mt-2 ml-2 font-medium absolute text-xs bg-purple-900  border-round-3xl text-white">
                Username</p>
              <InputText v-model="username" type="text" placeholder="ชื่อผู้ใช้งาน"
                class="w-full font border-none border-round-3xl text-purple-900 text-900 input-login z-5 bg-white" style="box-shadow: #d898f1 0px 10px 10px -5px; border-inline: 2px solid transparent; 
                box-shadow: #af3af3 0px 10px 10px -5px; padding-left: 20px; padding-right: 10px;"
                @keyup.enter="checklogin()" />
            </div>
          </div>
          <div class="col-12">
            <div class="field">
              <p
                class="pt-0 pr-2 pb-0 pl-2 -mt-2 mr-0 mb-0 ml-2 font-medium absolute text-xs bg-purple-900  border-round-3xl text-white">
                Password</p>
              <InputText v-model="password" type="password" placeholder="รหัสผ่าน"
                class="w-full  border-none border-round-3xl  text-900 input-login z-5 bg-white text-900"
                style="box-shadow: #d898f1 0px 10px 10px -5px; border-inline: 2px solid transparent; box-shadow: #af3af3 0px 10px 10px -5px; padding-left: 20px; padding-right: 10px;"
                @keyup.enter="checklogin()" />
            </div>
            <!-- <button type="button" class="accept-button" label="เข้าสู่ระบบ" @click="checklogin()" :loading="isLoading">ตกลง</button> -->
            <Button type="button"
              class="w-full justify-content-center font-bold border-round-3xl border-none mb-4 mt-2 login-button " style="background: linear-gradient(45deg, rgb(206, 43, 221) 0%, rgb(36, 3, 48) 100%); 
              box-shadow: rgba(152, 20, 201, 0.878) 0px 20px 10px -15px; transition: all 0.2s ease-in-out;"
              @click="checklogin()" :loading="isLoading">
              <svg width="24" height="24" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                style="transform: translateY(3px);">
                <g fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M7 13.5V8.25A1.25 1.25 0 0 1 8.25 7h0A1.25 1.25 0 0 1 9.5 8.25V11h2a2 2 0 0 1 2 2v.5" />
                  <circle cx="1" cy="1" r=".5" />
                  <circle cx="5" cy="1" r=".5" />
                  <circle cx="9" cy="1" r=".5" />
                  <circle cx="1" cy="4.5" r=".5" />
                  <circle cx="5" cy="4.5" r=".5" />
                  <circle cx="9" cy="4.5" r=".5" />
                  <circle cx="1" cy="8" r=".5" />
                  <path d="M5 8.5a.5.5 0 0 1 0-1Z" />
                </g>
              </svg> เข้าสู่ระบบ</Button>
          </div>
        </div>

      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import dayjs from 'dayjs';
export default {
  created() {
    document.title = "เข้าสู่ระบบ | NBA ONE STOP SHOP";
  },
  mounted() {
    if (this.$store.getters.logedIn === true) {
      this.$router.push("/");
    }
  },
  data: () => ({
    isLoading: false,
    username: "",
    password: "",
  }),
  methods: {
    async checklogin() {
      if (this.username === "" || this.password === "") {
        this.$toast.add({
          severity: "warn",
          summary: "แจ้งเตือน",
          detail: "กรุณากรอกชื่อผู้ใช้งานและรหัสผ่าน",
          life: 3000,
        });
        return false;
      }
      this.isLoading = true;
      await axios
        .post(`${process.env.VUE_APP_SHOP}login`, {
          username: this.username,
          password: this.password,
        })
        .then(async (res) => {
          if (res.data.level === "admin") {
            this.$toast.add({
              severity: "error",
              summary: "แจ้งเตือน",
              detail: "ไม่มีสิทธิ์ใช้งานระบบนี้",
              life: 3000,
            });
          } else if (res.data.level === "employee") {
            if (!res.data.result.employee_status) {
              this.$toast.add({
                severity: "error",
                summary: "ไม่สำเร็จ",
                detail: "บัญชีนี้ถูกปิดใช้งานไปแล้ว",
                life: 3000,
              });
            } else {
              await axios.post(`${process.env.VUE_APP_SHOP}login/history`, {
                name: res.data.result.name,
                ref: res.data.result,
                timestamp: dayjs(Date.now()).format()
              }, {
                headers: {
                  'auth-token': `Bearer ${res.data.token}`
                }
              })
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("shop_id", res.data.result.shop_id);
              window.location.assign("/");
            }
          } else {
            localStorage.setItem("token", res.data.token);
            window.location.assign("/");
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.$toast.add({
            severity: "error",
            summary: "ไม่สำเร็จ",
            detail: "ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง",
            life: 3000,
          });
        });
    },
  },
};
</script>

<style>
.container {
  display: flex;
  justify-content: right;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 110vh;
  background-image: url("../assets/img/login002.jpg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;

}

.modal {
  max-width: 30%;
  padding: 1rem 2rem;
  margin-top: 2%;
  /* Adjust this value to move the modal up */
  margin-right: 3%;
  margin-bottom: 5%;

}

.login-button:hover {
  transform: scale(1.03);
  box-shadow: rgba(208, 133, 215, 0.878) 0px 23px 10px -20px;

}

@media (max-width: 768px) {
  .container {
    background-position: center center;
    /* Center the background image */
    height: 100vh;
    /* Reduce the height for smaller screens */

  }

  .modal {
    max-width: 100%;
    margin: 10% auto;
    padding: 0;
  }
}

.bg-login {
  box-shadow: rgba(151, 4, 170, 0.878) 0px 30px 30px -20px;
  background-image: url('@/assets/img/bg-login.png');
  /* เปลี่ยน 'ชื่อไฟล์ภาพ.png' เป็นตำแหน่งของไฟล์ PNG ของคุณ */
  background-size: cover;
  /* แนะนำให้ใช้ cover หรือค่าอื่น ๆ ตามที่ต้องการ */
  background-repeat: no-repeat;
  /* แนะนำให้ไม่ทำซ้ำรูปภาพ */
  background-position: center center;
  /* ตำแหน่งของภาพในพื้นที่ */
}

.input-login {
  transition: all .2s ease;
}


.input-login:focus {
  border: 2px solid #3f0792 !important;
  background-color: aliceblue;
}
</style>

<style>
/* Media query for screens up to 768px wide */
@media (max-width: 768px) {
  .container {
    background-position: center center;
    height: 100vh;
  }

  .modal {
    max-width: 100%;
    margin: 10% auto;
    padding: 0;
  }
}

/* Media query for screens up to 1024px wide (e.g., iPads) */
@media (max-width: 1024px) {
  .modal {
    max-width: 100%;
  }
}

/* Media query for screens up to 1669px wide */
@media (max-width: 1669px) {
  .modal {
    margin-right: 2%;
    margin-top: 10%;
  }
}


/* เพิ่ม media query เพื่อปรับแต่งคลาส modal ในหน้าจอ 1080x870 */
@media (max-width: 1080px) and (max-height: 870px) {
  .modal {
    margin-top: 15%;
    /* ปรับค่านี้ให้ modal ขยับมากกว่าตรงบน */
    max-width: 40%;
  }
}

/* เพิ่ม media query เพื่อปรับแต่งคลาส modal ในหน้าขนาด 820x1180 */
@media (max-width: 820px) and (max-height: 1180px) {
  .modal {
    margin-top: 0%;
    /* ปรับค่า margin-top เพื่อปรับการแสดงผลของ modal ในหน้าขนาด 820x1180 */
    max-width: 70%;
    margin-right: 13%;
  }
}

/* เพิ่ม media query เพื่อปรับแต่งคลาส modal ในหน้าจอ 912x1368 */
@media (max-width: 912px) and (max-height: 1368px) {
  .modal {
    margin-top: 0%;
    /* ปรับค่า margin-top เพื่อปรับการแสดงผลของ modal ให้ตรงกลาง */
    max-width: 80%;
    /* ปรับความกว้างของ modal */
    margin-right: 10%;
    /* ปรับระยะของขอบขวา */
  }
}

/* เพิ่ม media query เพื่อปรับแต่งคลาส modal ในหน้าจอ 282x653 */
@media (max-width: 282px) and (max-height: 653px) {
  .modal {
    max-width: 100%;
    /* ปรับความกว้างของ modal ในหน้าจอขนาด 282x653 */
    margin-top: 5%;
    /* ปรับ margin-top เพื่อจัดการการแสดงผลในหน้าจอขนาดนี้ */
    margin-right: 0%;
    /* ปรับระยะขอบขวา */
  }
}
</style>


