<template>
    <div class="grid">
        <div class="col-12">
            <div class="grid">
                <div class="col-12 text-right">
                    <Button @click="print()" label="พิมพ์" icon="pi pi-print" />
                </div>
            </div>

            <ScrollPanel style="width: 100%; height: 700px; border-radius: 10px;" class="border-1 border-gray-300">
                <div id="printMe">
                    <div class="grid mt-2">
                        <div class="col-12 text-center">
                            <img src="@/assets/nbadigital.png" width="200" />
                            <h3 class="p-0 mb-0">บริษัท เอ็นบีเอ ดิจิตอล เซอร์วิส เซ็นเตอร์ จำกัด</h3>
                            <p class="p-0 text-center">เลขที่ 298/1 หมู่ที่3 ตำบลสันผักหวาน อำเภอหางดง จังหวัดเชียงใหม่
                                50230</p>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="col-12 text-center">
                            <h2 class="m-0">สัญญาเปิดศูนย์บริการลูกค้า ภายใต้แบรนด์ (NBA DIGITAL WORDLS)</h2>
                            <h2 class="m-0">เอ็นบีเอ วันสต๊อป ช็อป (ONE STOP SHOP)</h2>
                            <h2 class="m-0">เอ็นบีเอ วันสต๊อป เซอร์วิส (ONE STOP SERVICE)</h2>
                            <h2 class="m-0">เอ็นบีเอ สมาร์แพลตฟอร์ม (NBA SMART PLATFORM)</h2>
                            <h2 class="m-0">เอ็นบีเอ พาร์ทเนอร์ฝากขายสินค้า/บริการ (NBA PARTNER CONSIGNMENT)</h2>
                        </div>
                        <div class="col-12 p-5 pt-0">
                            <p style="text-indent: 1.5cm;">
                                สัญญาฉบับนี้จัดทำขึ้น ณ บริษัท เอ็นบีเอ ดิจิตอล เซอร์วิส เซ็นเตอร์ จำกัด เมื่อวันที่
                                &nbsp;<strong class="mark">{{
                                    getDay(partner.partner_date_start)
                                }}</strong>&nbsp;
                                เดือน &nbsp;<strong class="mark">{{
                                    getMonth(partner.partner_date_start)
                                }}</strong>&nbsp; พ.ศ.&nbsp;
                                <strong class="mark">{{ getYear(partner.partner_date_start) }}</strong> &nbsp; ระหว่าง
                                บริษัท เอ็นบีเอ ดิจิตอล เซอร์วิส เซ็นเตอร์ จำกัด
                                ทะเบียนบริษัทเลขที่ 0505565006177 สำนักงานใหญ่ตั้งอยู่เลขที่ 298/1 หมู่3 ตำบลสันผักหวาน
                                อำเภอหางดง จังหวัดเชียงใหม่ 50230 ซึ่งต่อไปในสัญญาฉบับนี้ เรียกว่า
                                <strong style="color:black">“ผู้ให้สิทธิเปิดศูนย์บริการ
                                    ลูกค้าภายใต้แบรนด์(NBA DIGITAL WORLDS) เอ็นบีเอ วันสต๊อป เซอร์วิส/ เอ็นบีเอ วันสต๊อป
                                    ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ”</strong>
                                ฝ่ายหนึ่งกับ
                                นาย/นาง/นางสาว &nbsp; <strong class="mark">{{ partner.partner_name }} </strong>
                                &nbsp; ถือบัตรประจำตัวประชาชนเลขที่ &nbsp;<strong class="mark">{{
                                    partner.partner_iden
                                }}</strong>&nbsp;
                                ที่อยู่ตามบัตรประชาชนเลขที &nbsp; <strong class="mark">{{
                                    partner.partner_address
                                }}</strong>
                                &nbsp;
                                แขวง/ตำบล &nbsp; <strong class="mark">{{ partner.partner_district }}</strong> &nbsp;
                                เขต/อำเภอ &nbsp; <strong class="mark">{{ partner.partner_state }}</strong> &nbsp;
                                จังหวัด &nbsp; <strong class="mark">{{ partner.partner_province }}</strong> &nbsp;
                                หมายเลขโทรศัพท์ &nbsp; <strong class="mark">{{ partner.partner_phone }}</strong> &nbsp;
                                ซึ่งต่อไปนี้เรียกว่า “ผู้รับสิทธิเปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป เซอร์วิส/เอ็นบีเอ
                                วันสต๊อปช็อป/เอ็นบีเอ
                                สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ” ในการให้บริการตามแผนการตลาดของ
                                บริษัทฯ การรับสมัครสมาชิก/การให้บริการสมาชิก การขายสินค้าทุกประเภท และบริการทุกประเภท
                                ของบริษัทฯ เอ็นบีเอ วันสต๊อป เซอร์วิส/เอ็นบีเอ วันสต๊อป
                                ช็อป/เอ็นบีเอสมาร์แพลตฟอร์ม/เอ็นบีเอ
                                พาร์ทเนอร์ ฝากขายสินค้าและบริการ โดยบริษัทฯ เป็นศูนย์กลางในการให้ข้อมูล และประสานงาน
                                ทั้งสองฝ่าย ตกลงทำสัญญากันดังมีข้อความดังต่อไปนี้

                            </p>
                            <p><strong>ข้อ 1.คำจำกัดความ</strong></p>
                            <p style="text-indent: 1.5cm"><strong>ลายมือชื่ออิเล็กทรอนิกส</strong> หมายถึง อักษร อักขระ
                                ตัวเลข เสียง หรือสัญลักษณ์อื่นใดที่สร้าง
                                ขึ้นให้อยู่ในรูปแบบอิเล็กทรอนิกส์ซึ่งนำมาใช้ประกอบกับข้อมูลอิเล็กทรอนิกส์
                                เพื่อแสดงความสัมพันธ์
                                ระหว่างบุคคลกับข้อมูลอิเล็กทรอนิกส์
                                โดยมีวัตถุประสงค์เพื่อระบุตัวตนบุคคลผู้เป็นเจ้าของลายมือชื่อ
                                อิเล็กทรอนิกส์ที่เกี่ยวข้องกับข้อมูลอิเล็กทรอนิกส์นั้น
                                และเพื่อแสดงว่าบุคคลดังกล่าวยอมรับข้อความ
                                ในข้อมูลอิเล็กทรอนิกส์นั้น</p>

                            <p style="text-indent: 1.5cm">
                                <strong>ผู้เปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป เซอร์วิส/เอ็นบีเอ วันสต๊อป ช็อป/เอ็นบีเอ
                                    สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ</strong>
                                หมายถึง ผู้เปิดศูนย์บริการลูกค้า
                                ทุกประเภทของบริษัทฯ เพื่อจำหน่ายสินค้าหรือให้บริการรวมถึงดำเนินการตามแผนการตลาดของ
                                บริษัทฯ ภายใต้ขอบเขต ของบริษัท เอ็นบีเอ ดิจิตอล เซอร์วิส เซ็นเตอร์ จำกัด
                            </p>
                            <p style="text-indent:1.5cm"><strong>ข้อมูลส่วนบุคคล</strong> หมายถึง
                                ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นไม่ว่า
                                ทางตรง หรือทางอ้อม รวมถึงข้อมูลส่วนบุคคลตามกฎหมายเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล</p>
                            <p style="text-indent: 1.5cm"><strong>ข้อมูลอันเป็นความลับ</strong> หมายถึง ข้อมูลส่วนบุคคล
                                หรือข้อมูลทางการค้า ความลับเชิงพาณิชย์
                                ข้อมูลที่เป็นความลับหรือทางการค้าอันเกี่ยวกับบริษัทฯ และบริษัทคู่สัญญาของบริษัทฯ
                            </p>
                            <p style="text-indent: 1.5cm"><strong>ทรัพย์สินทางปัญญา</strong> หมายถึง
                                สิทธิในทรัพย์สินทางปัญญาทั้งปวงอันรวมถึงแต่ไม่จำกัดเพียง
                                ลิขสิทธิ์ สิทธิบัตร เครื่องหมายทางการค้า เครื่องหมายบริการ ชื่อทางการค้า หรือการออกแบบ
                                ระบบงานใดที่เกิดจากการสร้างสรรค์ คิดค้นออกแบบ</p>

                            <p style="text-indent: 1.5cm"><strong>เหตุสุดวิสัย</strong> หมายถึง เหตุใดๆ
                                ที่คู่สัญญาไม่อาจป้องกัน หรือมิได้เกิดจากการกระทำของ
                                คู่สัญญานั้น แม้ว่าจะได้ใช้ความระมัดระวังตามสมควรอันพึงคาดหมายได้จากบุคคลในฐานะ และ
                                ภาวะเช่นนั้นซึ่งได้แก่ เหตุที่เกิดจากภัยธรรมชาติ เช่น น้ำท่วม ไฟไหม้ แผ่นดินไหว
                                หรือเกิดจากการใช้
                                อำนาจรัฐ หรือ การกระทำโดยบุคคลภายนอก หรือปัจจัยภายนอก</p>
                            <p style="text-indent: 1.5cm"><strong>สินค้า</strong> หมายถึง สินค้าที่เจ้าของสินค้าทำการผลิต
                                หรือนำเข้า หรือสินค้าประเภทอื่นๆ ที่เกี่ยวข้องที่มี ณ ปัจจุบัน หรือจะมีเพิ่มเติมในอนาคต
                                ที่เจ้าของสินค้าฝากให้แก่บริษัทฯเพื่อจัดจำหน่ายให้แก่ลูกค้า หรือสินค้าที่ทางบริษัทจัดหา
                                หรือผลิตเอง
                            </p>
                            <p style="text-indent: 1.5cm"><strong>(http://nbadigitalservice.com)</strong>
                                หมายถึงเว็บไซต์ของบริษัทฯ ในการซื้อขายสินค้า หรือ
                                บริการจัดการธุรกรรมซื้อขายผลิตภัณฑ์ต่างๆ และให้หมายความรวมถึงเว็บไซต์ชื่ออื่นๆ ของบริษัทฯ
                                ที่จะมีขึ้นในอนาคตด้วย
                            </p>
                            <p style="text-indent: 1.5cm"><strong>ทุกช่องทางของบริษัทฯ</strong> หมายถึง ช่องทางออนไลน์อื่นใด
                                ที่อยู่ภายใต้เว็บไซต์ของบริษัทฯ
                                ลูกค้า หมายถึง บุคคลธรรมดา ร้านค้า หรือนิติบุคคลทั่วไป
                                ที่ซื้อสินค้าจากเจ้าของสินค้าในลักษณะซื้อ
                                ขายเสร็จเด็ดขาดบนหน้าเว็บไซต์ (http://nbadigitalservice.com) หรือทุกช่องทางของบริษัทฯ</p>
                            <p><strong>ข้อ 2. การลงลายมือชื่อตามสัญญา</strong></p>
                            <p style="text-indent: 1.5cm">สัญญาเปิดศูนย์บริการลูกค้าฯ ฉบับนี้
                                คู่สัญญาตกลงกันว่านอกเหนือจากการทำสัญญาต่อหน้า
                                กันแล้วให้รวมถึงการทำสัญญาที่ไม่ได้เกิดต่อหน้ากันด้วย โดยทั้งนี้การจัดส่งสัญญา
                                หรือการลงลายมือชื่อในสัญญานี้ให้อยู่ภายใต้พระราชบัญญัติว่าด้วย ธุรกรรมทางอิเล็กทรอนิกส์ พ.ศ.
                                2544 โดยวิธีการหรือขั้นตอนนั้นให้เป็นไปตามที่บริษัทฯ กำหนด </p>
                            <p style="text-indent: 1.5cm">ผู้เปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป เซอร์วิส/เอ็นบีเอ
                                วันสต๊อป ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ
                                จะเป็นผู้เข้าสู่ระบบโดยรหัสผู้เข้าใช้งาน (USERNAME & PASSWORD) ซึ่งการที่ผู้เปิดศูนย์ฯ
                                เป็นผู้ถือ รหัสผู้เข้าใช้งาน แสดงว่าผู้เปิดศูนย์ฯ ยอมรับข้อความในข้อมูลอิเล็กทรอนิกส์นั้น
                                และถือเป็นการ
                                ลงลายมือชื่อภายใต้พระราชบัญญัติว่าด้วยธุรกรรมทางอิเล็กทรอนิกส์ พ.ศ. 2544 เสมือนว่าผู้เปิด
                                ศูนย์ฯ ได้ลงลายมือชื่อด้วยตัวเองแล้ว</p>

                            <p><strong>ข้อ 3. ความสัมพันธ์ตามสัญญา</strong></p>
                            <p style="text-indent: 1.5cm">ผู้เปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป เซอร์วิส/เอ็นบีเอ
                                วันสต๊อป ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ
                                ได้สมัครเพื่อให้บริการดำเนินการตาม
                                แผนการตลาดของบริษัทฯ เพื่อเป็นสื่อกลางในการให้บริการงาน ภายใต้ขอบเขตที่บริษัท เอ็นบีเอ
                                ดิจิตอล เซอร์วิส เซ็นเตอร์ จำกัด กำหนดและให้ข้อมูลข่าวสาร องค์ความรู้แก่ตัวแทนศูนย์ฯ ภายใต้
                                พื้นที่ ที่สมาชิกดูแล และรับสมัครสมาชิก ตลอดจนชี้แจงประสานงานและให้บริการข้อมูลข่าวสารแก่
                                สมาชิก และบริการอื่นๆ ตามแผนการตลาดของบริษัทฯ</p>
                            <p style="text-indent: 1.5cm">ผู้เปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป เซอร์วิส/เอ็นบีเอ
                                วันสต๊อป ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์
                                ฝากขายสินค้าและบริการจะได้รับหนังสือแต่งตั้งการเป็นตัวแทน
                                ตามประเภท ออกเป็นหนังสือติดตั้ง ไว้ ณ ศูนย์บริการของท่านเสมือนเป็นตัวแทนบริษัทฯ พร้อมทั้ง
                                เอกสารที่เกี่ยวข้อง</p>
                            <p style="text-indent: 1.5cm">ผู้เปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป เซอร์วิส/เอ็นบีเอ
                                วันสต๊อป ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ ทราบ
                                และเข้าใจเป็นอย่างดีว่าบริษัทฯ
                                เป็นผู้ให้บริการตลาดแบบตรง หรือสื่อกลางกับผู้ใช้บริการ และผู้บริโภคผ่านเว็บไซต์
                                <strong>(www.nbadigitalservice.com)</strong>
                                หรือทุกช่องทางของบริษัทฯเพื่อเป็นช่องทางในการซื้อขายสินค้า
                                หรือบริการจัดการธุรกรรมซื้อขายผลิตภัณฑ์ต่างๆ ซึ่งต่อไปนี้เรียกว่า “ระบบแพลตฟอร์ม” โดยผู้เปิด
                                ศูนย์ฯ ตกลงใช้บริการแพลตฟอร์ม และบริษัทฯ ตกลงให้ผู้เปิดศูนย์ฯ ใช้ระบบแพลตฟอร์มดังกล่าว
                                ตามประเภทที่ได้เลือกตามความสมัครใจของผู้เปิดศูนย์แต่ละประเภทฯ โดยใบแต่งตั้งทางบริษัทฯ
                                จะอนุมัติและออกให้ตามประเภทที่เลือกนั้นๆ
                            </p>

                            <p><strong>ข้อ 4. ค่าธรรมเนียม</strong></p>
                            <p style="text-indent: 1.5cm"><strong>1. ศูนย์บริการลูกค้าครบวงจรด้านธุรกิจ เอ็นบีเอ วันสต๊อป
                                    เซอร์วิส (NBA ONE STOPSERVICE) ณ วันทำสัญญาฉบับนี้ </strong></p>
                            <p style="text-indent: 1.5cm">ไม่มีการเรียกเก็บค่าธรรมเนียมในการเปิดศูนย์ฯ หรือการใช้
                                ระบบ <strong>ยกเว้น อุปกรณ์ในการดำเนินธุรกิจ</strong> สามารถสั่งซื้อกับทางบริษัทฯ
                                ได้ตามความสะดวก
                                ค่าธรรมเนียม/ค่าสัญญารายปีณ วันทำสัญญา ยังไม่มีการเรียกเก็บและไม่มีการเรียกเก็บย้อนหลัง
                                จนกว่าจะมีประกาศเปลี่ยนแปลง แจ้งให้ผู้ใช้ระบบทราบในอนาคต ผ่านทาง PLATFORM ระบบนั้นๆ
                                ทราบต่อไป</p>
                            <p style="text-indent: 1.5cm"><strong>2. ศูนย์บริการลูกค้าครบวงจรด้านธุรกิจและสินค้า เอ็นบีเอ
                                    วันสต๊อป ช็อป (NBA ONESTOP SHOP)</strong></p>
                            <p style="text-indent: 1.5cm">ซึ่งเป็นสาขาของ บริษัท เอ็นบีเอ ดิจิตอล เซอร์วิส เซ็นเตอร์ จำกัด
                                โดยตรงขึ้นตรงต่อการบริหารงานทุกระบบจากสำนักงานใหญ่ ในการจำหน่ายสินค้าและบริการทุกประเภท
                                ภายใต้
                                แบรนด์ เอ็นบีเอ ดิจิตอล เวิลด์ (NBA DIGITAL WORLDS) ในรูปแบบการลงทุนรายละเอียดดังนี้
                            </p>
                            <p style="text-indent: 1.5cm">2.1) บริษัท เอ็นบีเอฯ ให้ยืมในอุปกรณ์
                                ตามแบบสัญญายืมใช้อุปกรณ์และลงสินค้า ตลอดถึง
                                การส่งเสริมการตลาด อาจจะมีการกำหนดค่าธรรมเนียมในการยืม หรือเช่าอุปกรณ์นั้นก็ได้ตามสัญญา
                                ยืม แยกจากสัญญาเปิดศูนย์ฯ ฉบับน</p>
                            <p style="text-indent: 1.5cm">2.2) ผู้บริหารศูนย์สาขานั้นๆ ตามหนังสือแต่งตั้ง หรือเจ้าของสถานที่
                                ต้องยินยอมให้ใช้
                                สถานที่ในการเปิดศูนย์บริการในรูปแบบ “SHOP” ตามแบบของทางบริษัทฯ กรณีสถานที่นั้นเป็นการ
                                เช่า ต้องมีระยะเวลา 3 ปี นับจากวันเปิดทำการจำหน่ายสินค้าและบริการของทางบริษัทฯ และได้รับ
                                ความยินยอมจากเจ้าของสถานที่ หรือถือกรมสิทธิตามกฎหมายนั้น</p>
                            <p style="text-indent: 1.5cm">2.3) ผู้บริหารศูนย์ จะได้รับส่วนแบ่งกำไร จากการจำหน่ายสินค้า
                                ตามแผนการตลาดของบริษัทฯ 80% จากกำไรสุทธิทุกๆ วัน โดยสรุปจากการปิดยอดขายประจำวัน
                                หรือกรณีที่ระบบปิดยอดขายอัตโนมัติ 00.00 น. <strong>โดยคำนวณจากยอดขายในแต่ละวัน
                                    หักต้นทุนสินค้า หักส่วนลดและจะได้รับรายได้ในรูปแบบค่าคอมมิชชั่น โดยหักภาษี ณ ที่จ่าย 3%
                                    แล้ว
                                </strong>ตามรายงานการขายในระบบของแต่ละวัน โดยทุกสาขา ต้องนำส่งต้นทุนสินค้า
                                และกำไรในส่วนของบริษัทฯ 20% และภาษี ณ ที่จ่าย 3% ที่ถูกหัก จากบริษัทฯ เพื่อนำส่งสรรพากรต่อไป
                                ทุกสาขาต้องนำส่งเงินยอดขาย
                                (ต้นทุนบวกกำไรในส่วนของบริษัท และภาษี ณ ที่จ่ายที่ถูกหัก) เข้าบริษัทฯ ทุกวันหากมีการนำส่ง
                                ล่าช้าเกิน 1 วัน (จากวันที่ปิดยอดขายจากเวลา 00.00 ของวันนั้น ไปอีก 24 ชั่วโมง) จะมีค่าปรับ
                                และค่าติดตามวันละ 300 บาท จนกว่าจะมีการชำระยอดเข้ามาทางบริษัทฯ หากไม่ชำระเกิน 2 รอบใบ
                                แจ้งชำระ ระบบจะล็อค สาขาจะไม่สามารถทำรายการใดผ่านระบบของบริษัทฯ ได้ทุกระบบ หาก
                                เวลาล่วงเลยเกิน 10 วัน นับตั้งแต่ระบบถูกล็อค ทางบริษัทฯ ถือว่าสาขา ผู้บริหารศูนย์
                                (ผู้รับค่าคอมมิชชั่น) จงใจยักยอกทรัพย์ และผิดสัญญา สัญญาถือเป็นอันสิ้นสุด โดยที่บริษัท ไม่
                                จำเป็นต้องบอกเลิกสัญญาเป็นหนังสือ โดยถือว่าสัญญาฉบับนี้ได้แถลงข้อความที่ สาขานั้นฯ โดย
                                ผู้บริหารศูนย์ (ผู้รับค่าคอมมิชชั่น) และผลของการยกเลิกสัญญาผู้บริหารศูนย์ ต้องส่งมอบ อุปกรณ์
                                และสินค้าให้อยู่ในสภาพใช้งานปกติ และสินค้าตามยอดคงเหลือในระบบ และชำระค่าเสียหายเพิ่ม
                                จากสินค้าที่หายจากการตรวจสอบ และค่าปรับจากการผิดสัญญาจำนวน 200,000 บาท กรณีที่ถูก
                                ยกเลิกสัญญาก่อนเวลา 3 ปี ตามสัญญาฉบับนี้กำหนดแต่ต้น
                                โดยให้ยึดจากเงินค้ำประกันสัญญา<strong>“เงินประกันสัญญา” ตามสัญญาฉบับนี้ 200,000 บาท
                                    (สองแสนบาทถ้วน)</strong>หากความเสียหาย
                                มากกว่า จำนวนเงินประกันสัญญา บริษัทฯ สามารถฟ้องร้องเรียกค่าเสียหายได้จนกว่าจะครบ
                                ค่าสินค้าสูญหายและความเสียหายที่เกิดขึ้นเหตุเกิดจากการยกเลิกสัญญา สามารถนำข้อ 24 มาถือ
                                ปฏิบัติ
                            </p>
                            <p style="text-indent: 1.5cm">2.4) ผู้บริหารศูนย์ สาขานั้นๆ ต้องติดกล้องวงจรปิด ตามที่บริษัท
                                เอ็นบีเอ ฯ กำหนดเท่านั้น
                                เพื่อดูแลความปลอดภัยของทรัพย์สิน และลูกค้า อันควรจะมีและปฏิบัติ ตลอดอนุญาตให้ทางบริษัทฯ
                                เข้าดูและบันทึกไฟล์ภาพพร้อมเสียงตลอดเวลา และตลอดระยะเวลาของสัญญาฉบับนี้ใช้บังคับ
                                ค่าใช้จ่ายที่เกิดขึ้นจากการติดกล้องวงจรทางผู้บริหารศูนย์ฯ สาขานั้นๆ เป็นผู้รับผิดชอบ
                            </p>
                            <p style="text-indent: 1.5cm">2.5) ผู้บริหารศูนย์ สาขานั้นๆ ต้องติดตั้งอินเตอร์เน็ต ตามที่บริษัท
                                เอ็นบีเอฯ กำหนดเท่านั้น
                                เพื่อประสิทธิภาพในการบริหารจัดการ และแก้ไขปัญหาในกรณีที่เกิดปัญหาด้านเทคนิค โดยผู้บริหาร
                                ศูนย์นั้นๆ เป็นผู้รับผิดชอบค่าใช้จ่ายรายเดือน โดยทางสำนักงานใหญ่ บริษัทฯ
                                จะเป็นผู้เรียกเก็บราย
                                เดือนต่อไป
                            </p>
                            <p style="text-indent: 1.5cm">2.6) ผู้บริหารศูนย์สาขานั้นๆ เป็นผู้รับผิดชอบในบรรดา
                                ค่าตกแต่งเพิ่มเติม ค่าเช่า ภาษีป้าย
                                ภาษีโรงเรือนและที่ดินและค่าธรรมเนียมอื่นๆ แต่เพียงผู้เดียวในส่วนภาษี ในส่วนภาษีขายทางบริษัทฯ
                                (สำนักงานใหญ่) เป็นผู้รับผิดชอบ และนำส่งกรมสรรพากรต่อไป
                            </p>
                            <p style="text-indent: 1.5cm">2.7) ผู้บริหารศูนย์สาขานั้นๆ ต้องดูแล ปรับปรุง
                                และทำความสะอาดช็อปให้สะอาด และ
                                จัดเรียงสินค้าต่างๆ ตามระเบียบของบริษัทฯ อาธิเช่น 5ส ให้อยู่ในสภาพเรียบร้อยสม่ำเสมอ
                            </p>
                            <p style="text-indent: 1.5cm">2.8) ผู้บริหารศูนย์ สาขานั้นๆ ต้องอนุญาตให้
                                พนักงานเจ้าหน้าที่ของบริษัทฯ ตรวจสอบระบบ
                                สินค้าและอุปกรณ์ต่างๆ ในศูนย์ได้ตลอดเวลา โดยบอกกล่าวหรือไม่ต้องบอกกล่าวล่วงหน้าก็ได</p>
                            <p style="text-indent: 1.5cm">2.9) ค่าธรรมเนียมรายเดือน/รายปี ในการใช้บริการระบบ หรือระบบอื่น
                                ยังไม่มีการเรียกเก็บ
                                ใดๆ และไม่มีการเรียกเก็บย้อนหลัง จนกว่าจะมีการแจ้ง และประกาศเป็นลายลักษณ์อักษรในอนาคต
                            </p>
                            <p style="text-indent: 1.5cm"><strong>3. เอนบีเอ สมาร์ท แพลตฟอร์ม “NBA SMART PLATFORM”</strong>
                            </p>
                            <p style="text-indent: 1.5cm">สำหรับสมาชิกทั่วไป
                                นอกเหนือจากข้อ (1) (2) ข้างต้น สามารถใช้บริการทุกบริการที่ทางบริษัทฯ อนุมัติให้ใช้ในปัจจุบัน
                                และอนาคต ตลอดจนสามารถซื้อสินค้า และอื่นๆใน PLATFORM ภายใต้กฎและระเบียบของบริษัทฯ
                                ตลอดจนถึงการส่งเสริมการตลาดของบริษัทฯ ค่าธรรมเนียม รายเดือน รายปี ยังไม่มีการเรียกเก็บ
                                จนกว่าจะมีประกาศเป็นทางการให้ทราบในอนาคต
                            </p>

                            <p><strong>ข้อ 5. การขายฝากสินค้าผ่านแพลตฟอร์ม</strong></p>
                            <p style="text-indent: 1.5cm">บริษัท เอ็นบีเอ ดิจิตอล เซอร์วิส เซ็นเตอร์ จำกัด
                                มีแพลตฟอร์มในการให้ผู้เปิดศูนย์ฯ
                                ทุกประเภท (ซึ่งต่อไปนี้จะเรียกว่า “เจ้าของสินค้า” ) สามารถรับฝากขายสินค้าโดยมีระยะเวลาในการ
                                ฝากขายสินค้าไปจนกว่าสัญญาฉบับนี้จะถูกยกเลิกหรือเจ้าของสินค้ากระทำความผิดสัญญาข้อหนึ่งข้อ
                                ใด บริษัทฯ มีสิทธิบอกเลิกสัญญาทันที และเจ้าของสินค้าตกลงจะมารับสินค้าคืนภายใน 15 วัน หาก
                                เกินกำหนดระยะเวลาดังกล่าวไปแล้วบริษัทฯ จะไม่รับผิดชอบความเสียหายที่เกิดขึ้นกับสินค้าทุกกรณี

                            </p>
                            <p style="text-indent: 1.5cm">5.1. <u>มีข้อตกลง และเงื่อนไข การส่งมอบ
                                    และการจำหน่ายสินค้าดังต่อไปนี้ คือ
                                </u></p>
                            <p style="text-indent: 1.5cm">5.1.1) บริษัทฯ ตกลงจำหน่ายสินค้าแทนเจ้าของสินค้าบนเว็บไซต์
                                (http://nbadigitalservice.com) และ/หรือทุกช่องทางของบริษัทฯ โดยในการสั่งสินค้าเข้ามา
                                จำหน่ายแทนเจ้าของสินค้าในแต่ละครั้ง เจ้าของสินค้ายินยอมให้บริษัทฯ เป็นผู้กำหนด ซึ่งราคา
                                จำหน่ายในสินค้าแต่ละประเภทให้เป็นไปตามที่คู่สัญญาทั้งสองฝ่ายตกลงร่วมกัน
                            </p>
                            <p style="text-indent: 1.5cm">5.1.2) เจ้าของสินค้ามีหน้าที่ ให้รายละเอียดสินค้า รูปภาพ
                                คุณสมบัติของสินค้า
                                ใบอนุญาตที่กฎหมายกำหนดต้องมีให้ครบถ้วน ตลอดจนการนำเสนอสินค้า ต้องเป็นไปตามรูปแบบที่
                                บริษัทฯ กำหนดเท่านั้น ทั้งนี้ด้วยค่าใช้จ่ายของเจ้าของสินค้าเองทั้งสิ้น
                                และเจ้าของสินค้าต้องให้รายละเอียดข้อมูลกับบุคลากรของบริษัทฯ เพื่อให้มีความรู้
                                ความเข้าใจในตัวสินค้าที่จำหน่ายของ
                                เจ้าของสินค้า ด้วยค่าใช้จ่ายของเจ้าของสินค้าเองทั้งสิ้น</p>
                            <p style="text-indent: 1.5cm">5.1.3) เจ้าของสินค้ามีหน้าที่ช่วยเหลือ
                                และให้การสนับสนุนเกี่ยวกับการโฆษณาการประชาสัมพันธ์ และการส่งเสริมการขายให้แก่บริษัทฯ
                                ตามความเหมาะสม ด้วยการช่วยเหลือทาง
                                เทคนิคในการดำเนินธุรกิจ การจัดให้มีการโฆษณา และการประชาสัมพันธ์เช่น การจัดหาแผ่นโฆษณา
                                แผ่นพับ ใบปลิว และบัญชีรายการสินค้า</p>
                            <p style="text-indent: 1.5cm">5.1.4) เจ้าของสินค้าจะต้องดำเนินการจัดส่งสินค้าไปยังลูกค้า
                                ตามคำสั่งของบริษัทฯและสินค้าที่จัดส่งจะต้องมีสภาพสมบูรณ์
                                และต้องเป็นสินค้าที่ยังไม่หมดอายุเปลี่ยนสภาพหรือ
                                เสื่อมสภาพ ตามใบสั่งสินค้ามาพร้อมกับใบส่งของ และสำเนาใบกำกับภาษี ด้วยค่าใช้จ่ายของเจ้าของ
                                สินค้าทั้งสิ้น กรณีที่สินค้าไม่ได้เก็บไว้ในความดูแลของบริษัท เอ็นบีเอฯ
                            </p>
                            <p style="text-indent: 1.5cm">5.1.5) การส่งมอบสินค้าให้แก่ลูกค้า
                                จะถือว่าสมบูรณ์เมื่อลูกค้าได้รับสินค้าจากเจ้าของสินค้า และดำเนินการตรวจสอบถึงความชำรุด
                                บกพร่อง ความถูกต้อง เบื้องต้น ตามลักษณะหีบห่อบรรจุภัณฑ์
                                ตามที่ตกลงกันครบถ้วนในตัวสินค้าที่จำหน่าย พร้อมเซ็นต์รับสินค้าในใบส่งของเรียบร้อยแล้ว
                            </p>
                            <p style="text-indent: 1.5cm">5.1.6)
                                หากลูกค้าตรวจสอบสินค้าแล้วปรากฏว่าไม่ถูกต้องครบถ้วนตามที่กำหนดในใบ
                                ส่งของ หรือ พบความชำรุดบกพร่องในตัวสินค้า บริษัทฯ จะแจ้งเจ้าของสินค้าทราบทันที และเจ้าของ
                                สินค้าจะต้องทำการเปลี่ยนสินค้าดังกล่าวภายใน 3 วัน นับจากวันที่บริษัทฯ
                                แจ้งให้เจ้าของสินค้าทราบเพื่อให้ลูกค้าได้รับสินค้าที่ถูกต้องตรงตามจำนวนที่สั่งซื้อ
                            </p>
                            <p style="text-indent: 1.5cm">5.2. <u>กรรมสิทธิ์ในสินค้า</u></p>
                            <p style="text-indent: 1.5cm">ในการซื้อขายสินค้าระหว่างเจ้าของสินค้า และลูกค้าตามสัญญานี้
                                ให้ถือว่ากรรมสิทธิ์ของสินค้ายังเป็นของเจ้าของสินค้าอยู่
                                จนกว่าสินค้าจะได้ถูกจำหน่ายไปยังลูกค้าแล้ว</p>
                            <p style="text-indent: 1.5cm">5.3. <u>การส่งมอบเงินค่าสินค้า และส่วนแบ่งรายได้</u></p>
                            <p style="text-indent: 1.5cm">5.3.1)
                                เจ้าของสินค้าตกลงจ่ายส่วนแบ่งรายได้ในการดำเนินการจำหน่ายสินค้าให้กับ
                                บริษัทฯ ในอัตราตามตกลงของราคาจำหน่าย รายละเอียดตามเอกสารแนบ และให้ถือเป็นส่วนหนึ่ง
                                ของสัญญาฉบับนี้
                            </p>
                            <p style="text-indent: 1.5cm">5.3.2) บริษัทฯ และเจ้าของสินค้าจะทำสรุปยอดขายรวมกันทุกๆ สัปดาห์
                                กำหนดการวางบิลสินค้า หรือเอกสารที่เกี่ยวข้อง โดยสรุปยอด และจะโอนจ่ายเงินภายใน 20 วันทำการ
                                หรือก่อนแล้วแต่กรณีที่บริษัทฯ จะปฏิบัติแต่ไม่เกินระยะเวลาข้างต้น
                                หลังจากวางบิลในกรณีที่สินค้าที่ลูกค้า
                                ได้รับนั้นไม่เกิดความเสียหาย และไม่มีการร้องเรียนเนื่องจากคุณภาพสินค้า และบริษัทฯ
                                จะส่งมอบค่าสินค้าที่จำหน่ายได้ตาม
                                (ข้อ 5.3.1) ให้แก่เจ้าของสินค้าภายหลัง จากหักส่วนแบ่งรายได้ และค่าใช้จ่ายต่างๆ ภายในไม่เกิน
                                20 วันทำการ หรือเร็วกว่าตามที่บริษัทฯ จะปฏิบัติ แต่ไม่เกินระยะเวลาข้างต้น
                            </p>
                            <p style="text-indent: 1.5cm;">
                                5.3.3) ผู้รับสิทธิเปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป เซอร์วิส/ เอ็นบีเอ วันสต๊อป
                                ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ มีหน้าที่แสดง
                                รายการขายสินค้าและจำนวนสต๊อกสินค้าผ่านระบบแพลตฟอร์มให้กับบริษัทฯ ในทุกๆ วัน หรือตามที่
                                บริษัทฯ ร้องขอ
                            </p>
                            <p style="text-indent: 1.5cm">
                                5.3.4) ผู้รับสิทธิเปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป เซอร์วิส/ เอ็นบีเอ วันสต๊อป
                                ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการยินยอมให้บริษัทฯ
                                ตรวจดูร้านได้ตลอดเวลาที่ร้านเปิดทำการ ผ่านทางกล้องวงจรปิดที่ทางบริษัทฯ ทำการติดตั้งไว้ใน
                                ร้านค้าตามสัญญาฉบับนี้
                            </p>
                            <p style="text-indent: 1.5cm">
                                5.3.5) ผู้รับสิทธิเปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป เซอร์วิส/ เอ็นบีเอ วันสต๊อป
                                ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ ยินยอมให้บริษัทฯ
                                ตรวจสอบจำนวนรายการขายสินค้ากับจำนวนสต๊อกของสินค้า หากจำนวนรายการขายสินค้ากับ
                                จำนวนสต๊อกของสินค้าไม่ตรงกัน ทางบริษัทฯ จะทำการปรับยอด รายการขายสินค้ากับจำนวนสต๊อก
                                ของสินค้าให้ตรงกันแล้วทำการสรุปเป็นยอดขายสินค้าและจำนวนสต๊อกของสินค้าในวันนั้นและทาง
                                บริษัทฯ จะทำการส่งหนังสือเตือนให้ผู้รับสิทธิ เปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป
                                เซอร์วิส/เอ็นบีเอ วันสต๊อป ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์
                                ฝากขายสินค้าและบริการ
                                ให้แสดงจำนวนรายการขายสินค้ากับจำนวนสต๊อกของสินค้าให้ตรงกันในครั้งถัดไป หากทางบริษัทฯ
                                ทำการตรวจพบจำนวนรายการขายสินค้ากับจำนวนสต๊อกของสินค้าไม่ตรงกันอีกครั้งหนึ่ง ทาง
                                ผู้บริหารศูนย์ฯ สามารถอุทธรณ์ผ่านทางบริษัทฯ ถึงเหตุผลนั้น การพิจารณาการอุทธรณ์ของบริษัทฯ
                                ถือเป็นที่สุด และทางบริษัทฯ มีสิทธิบอกเลิกสัญญาและเรียกค่าเสียหายได
                            </p>
                            <p style="text-indent: 1.5cm">5.4. <u>หน้าที่ และความรับผิดชอบของคู่สัญญา</u></p>
                            <p style="text-indent: 1.5cm">เจ้าของสินค้าต้องปกป้อง คุ้มครอง คุ้มกันบริษัทฯ พนักงานของบริษัทฯ
                                และ/หรือผู้จำหน่ายสินค้าของบริษัทฯ อื่นๆ จากการถูกเรียกร้อง ฟ้องร้อง และต้องรับผิดชอบ
                                และชดใช้ค่าเสียหายที่
                                เกิดขึ้นให้แก่ผู้เสียหาย (ลูกค้า) เช่น สินค้าสูญหาย เสียหาย เป็นต้น รวมถึงข้อมูลใดๆ
                                ที่ได้รับจากเจ้าของสินค้าอันเป็นเท็จ และ/หรือโฆษณาเกินจริง บนตัวสินค้า
                                และ/หรือทรัพย์สินทางปัญญาของ
                                บุคคลอื่น หรือการกระทำอันเป็นฝ่าฝืนต่อพระราชบัญญัติคุ้มครองผู้บริโภค ซึ่งเป็นเหตุให้ลูกค้า
                                หรือบริษัทฯ พนักงานของบริษัทฯ หรือผู้จำหน่ายสินค้าของบริษัทฯ ได้รับความเสียหาย ตลอดจนถึงการ
                                ดำเนินการใดๆเพื่อให้กรณีดังกล่าวยุติโดยเร็วที่สุด และบริษัทฯ
                                อาจถือเงื่อนไขดังกล่าวเป็นเหตุแห่ง
                                การบอกเลิกสัญญาฉบับนี้ได้ ค่าเสียหายดังกล่าว รวมถึงแต่ไม่จำกัดเพียงค่าที่ปรึกษาค่าทนายความ
                                ค่าธรรมเนียมศาล หรือของราชการอื่นๆ ค่าเสียหายจากการประนีประนอมยอมความด้วย </p>
                            <p style="text-indent: 1.5cm">5.5 <u>การคืนสินค้า</u></p>
                            <p style="text-indent: 1.5cm">เจ้าของสินค้ายินยอมให้รับคืนสินค้าของบริษัทฯ
                                ที่นำมาวางจำหน่ายบนเว็บไซต์ (www.nbadigitalservice.com) หรือทุกช่องทางบริษัทฯ
                                ได้ในกรณีดังต่อไปนี้</p>
                            <p style="text-indent: 1.5cm">5.5.1) สินค้าที่บริษัทฯ ได้รับมาวางจำหน่ายไม่สามารถจำหน่าย
                                และไม่เป็นไปตามความต้องการของลูกค้า ภายในรอบการใดๆ ที่บริษัทฯ กำหนด</p>
                            <p style="text-indent: 1.5cm">5.5.2) สินค้าที่ละเมิด หรืออาจละเมิด (โดยความเห็นของบริษัทฯ
                                เป็นที่สุด) ลิขสิทธิ์สิทธิบัตร เครื่องหมายการค้าหรือ ทรัพย์สินทางปัญญา หรือ ผิดกฎหมาย
                            </p>
                            <p style="text-indent: 1.5cm">5.5.3) สินค้าที่ลูกค้านำมาคืน หรือเปลี่ยน เนื่องจากชำรุด บกพร่อง
                                หรือไม่ได้มาตรฐานอันเนื่องจากกระบวนการผลิต การขนส่งสินค้า
                            </p>
                            <p style="text-indent: 1.5cm">5.5.4) สินค้าที่หมดอายุ เปลี่ยนสภาพ หรือเสื่อมสภาพ
                                ก่อนระยะเวลาอันเป็นปกติสำหรับสินค้า ประเภทนั้นๆ
                                ทั้งนี้การคืนสินค้าดังกล่าวให้ถือเป็นดุลพินิจเด็ดขาด
                                และเป็นที่ยุติของบริษัทฯ ในการคืนสินค้าไปยังเจ้าของสินค้า
                            </p>

                            <p><strong>ข้อ 6. เครื่องหมาย หรือสัญลักษณ์ ทรัพย์สินทางปัญญา</strong></p>
                            <p style="text-indent: 1.5cm">เครื่องหมายหรือสัญลักษณ์การเป็นตัวแทนของ บริษัท เอ็นบีเอ ดิจิตอล
                                เซอร์วิส เซ็นเตอร์จำกัด ตลอดจนเว็บไซต์แอพพลิเคชั่น และสื่อการตลาดทุกรูปแบบ
                                รวมถึงทรัพย์สินทางปัญญา
                                นวัตกรรม ฯลฯ ของบริษัท เอ็นบีเอ ดิจิตอล เซอร์วิส เซ็นเตอร์ จำกัด เพื่อให้ใช้สิทธิต่างๆ
                                ตามสัญญาฉบับนี้มีผลบังคับใช้เพื่อให้มีการเปิดศูนย์บริการ เอ็นบีเอ วันสต๊อป เซอร์วิส/
                                เอ็นบีเอ
                                วันสต๊อป ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์
                                ฝากขายสินค้าและบริการเป็นไปตามที่ส่วนกลาง
                                กำหนด ห้ามดัดแปลง แก้ไข โดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจากทางบริษัทฯ หากมีการ
                                ดัดแปลง แก้ไข โดยไม่ได้รับอนุญาต แล้วเกิดความเสียหายแก่บุคคลภายนอก บริษัท เอ็นบีเอ ดิจิตอล
                                เซอร์วิส เซ็นเตอร์ จำกัด จะไม่รับผิดชอบทุกกรณี และทางผู้เปิดศูนย์ฯ จะต้องเป็นผู้รับผิดต่อ
                                บุคคลภายนอกแต่เพียงผู้เดียว
                            </p>
                            <p><strong>ข้อ 7. ความลับทางการค้า</strong></p>
                            <p style="text-indent: 1.5cm">ความลับทางการค้าในระยะเวลาการเปิดศูนย์บริการลูกค้า เอ็นบีเอ
                                วันสต๊อป เซอร์วิส/ เอ็นบี
                                เอ วันสต๊อป ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ ตาม
                                สัญญาฉบับนี้ ผู้เปิดศูนย์ฯ จะไม่เปิดเผยความลับทางการค้า ไม่ว่าจะเป็นข้อมูลส่วนบุคคล
                                หรือข้อมูล
                                ทางการค้า ความลับเชิงพาณิชย์ ไม่ว่าจะมีมูลค่า หรือไม่ก็ตาม และจะไม่เปิดเผย ตีพิมพ์ ประกาศ
                                หรือเผยแพร่ต่อบุคคลภายนอก เว้นแต่จะเป็นการกระทำตามกฎหมาย หรือได้รับความยินยอมเป็น
                                ลายลักษณ์อักษรจากทางบริษัทฯเสียก่อน
                            </p>
                            <p><strong>ข้อ 8. เหตุสุดวิสัย</strong></p>
                            <p style="text-indent: 1.5cm">บริษัทฯ ไม่ต้องรับผิดต่อผู้เปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป
                                เซอร์วิส/ เอ็นบีเอ
                                วันสต๊อป ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ ไม่ว่าใน
                                ลักษณะใดหรือไม่อาจถือได้ว่าบริษัทฯ เป็นผู้ละเมิดเงื่อนไขเหล่านี้ เนื่องมาจากความล่าช้าในการ
                                ปฏิบัติตามสัญญา หรือการไม่สามารถปฏิบัติหน้าที่ตามข้อผูกพันของตนภายใต้เงื่อนไขเหล่านี้ หาก
                                ความล่าช้า หรือการไม่สามารถปฏิบัติหน้าที่ได้นั้นเป็นผลมาจากเหตุใดๆ ที่อยู่เหนือการควบคุมที่
                                สมเหตุสมผลของบริษัทฯ (รวมถึงแต่ไม่จำกัดเพียงการกรทำของรัฐบาล สงคราม อัคคีภัย การระเบิด
                                อุทกภัย ระเบียบข้อบังคับว่าด้วยการนำเข้า และการส่งออก และการกักกันเรือสินค้า ข้อพิพาททาง
                                แรงงานหรือการไม่สามารถได้มาซึ่งสินค้าหรือความล่าช้าในการได้มาซึ่งสินค้าหรือแรงงาน)
                                (แต่ละสิ่ง
                                ที่กล่าวไปล้วนเป็น “เหตุสุดวิสัย”) เมื่อเกิดเหตุสุดวิสัยขึ้น บริษัทฯ
                                อาจเลือกที่จะชะลอการปฏิบัติตาม
                                สัญญาฉบับนี้ และภายในระยะเวลา 3 ปี นับตั้งแต่วันที่สัญญาฉบับนี้สิ้นสุดลง หากผู้เปิดศูนย์ฯ
                                ประพฤติผิดเงื่อนไขสัญญานี้ บริษัทฯ มีสิทธิ์เรียกร้องให้ผู้เปิดศูนย์ฯ
                                รับผิดชอบความเสียหายตามที่
                                เกิดขึ้น</p>
                            <p><strong>ข้อ 9. การโอนสิทธิ</strong></p>
                            <p style="text-indent: 1.5cm">เว้นแต่จะได้กำหนดไว้เป็นอย่างอื่นในสัญญานี้
                                คู่สัญญาแต่ละฝ่ายตกลงจะไม่โอนสิทธิ หน้าที่
                                และความรับผิดชอบ สัญญาฉบับนี้ให้แก่บุคคลใดโดยมิได้รับความยินยอมเป็นลายลักษณ์อักษรจาก
                                คู่สัญญาอีกฝ่ายหนึ่งเป็นการล่วงหน้า
                            </p>
                            <p><strong>ข้อ 10. โมฆะกรรมแยกส่วน</strong></p>
                            <p style="text-indent: 1.5cm">หากมีข้อความ หรือข้อตกลงใดในสัญญาฉบับนี้ตกเป็นโมฆะ ไม่สมบูรณ์
                                หรือไม่มีผลบังคับใช้
                                ไม่ว่าด้วยเหตุใดๆ
                                ก็ตาม คู่สัญญาตกลงให้ข้อความ และข้อตกลงอื่นๆ ในสัญญาฉบับนี้ยังคงมีผลสมบูรณ์ และผูกพัน
                                คู่สัญญาอยู่เสมือนหนึ่งว่าไม่มีส่วนที่เป็นโมฆะ ไม่สมบูรณ์
                                หรือส่วนที่ไม่มีผลบังคับใช้อยู่ในสัญญาฉบับ
                                นี้</p>
                            <p><strong>ข้อ 11. สิทธิในทรัพย์สินทางปัญญา</strong></p>
                            <p style="text-indent: 1.5cm">สิทธิในทรัพย์สินทางปัญญาทั้งปวงอันรวมถึงแต่ไม่จำกัดเพียง ลิขสิทธิ์
                                สิทธิบัตร เครื่องหมาย
                                การทางการค้า เครื่องหมายบริการ ชื่อทางการค้า หรือการออกแบบ ระบบงานใดที่เกิดจากการ
                                สร้างสรรค์ คิดค้น ออกแบบ ตามสัญญาฉบับนี้ให้ตกเป็นสิทธิ เด็ดขาดของบริษัทฯ ทั้งสิ้น</p>
                            <p><strong>ข้อ 12. ข้อมูลส่วนบุคคล</strong></p>
                            <p style="text-indent: 1.5cm">บริษัทฯ อาจมีการเปิดเผยข้อมูลส่วนบุคคลของผู้เปิดศูนย์บริการลูกค้า
                                เอ็นบีเอ วันสต๊อป
                                เซอร์วิส/ เอ็นบีเอ วันสต๊อป ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและ
                                บริการ ภาพถ่าย/วิดีโออาจถูกนำไปใช้ในสิ่งตีพิมพ์ สื่อโฆษณา เว็บไซต์ และแพลตฟอร์มสื่อสังคม
                                ในภาพยนตร์ออนไลน์ และสื่อการตลาดอื่นๆ เท่าที่จำเป็น หากผู้เปิดศูนย์ฯ ได้กระทำการลงลายมือ
                                ชื่อในสัญญาฉบับนี้แล้วให้ถือว่าผู้เปิดศูนย์ฯ
                                ยินยอมให้บริษัทฯเปิดเผยข้อมูลส่วนบุคคลของผู้เปิด
                                ศูนย์ฯ เท่าที่จำเป็น ผู้เปิดศูนย์ มีสิทธิในการขอสำเนาข้อมูล สิทธิในการเข้าถึงข้อมูล
                                สิทธิในการแก้ไข
                                เปลี่ยนแปลงข้อมูล สิทธิในการเพิกถอนความยินยอม และสิทธิในการมีมาตรการรักษาความมั่นคง
                                ความปลอดภัยส่วนบุคคลของผู้เปิดศูนย์ ได้โดยแจ้งความประสงค์ที่จะเพิกถอนความยินยอมต่อ
                                บริษัทฯ
                            </p>
                            <p><strong>ข้อ 13. การแก้ไข เปลี่ยนแปลง ยกเลิก ระบบทุกรูปแบบ</strong></p>
                            <p style="text-indent: 1.5cm">บริษัทฯ มีสิทธิอนุมัติ เปลี่ยนแปลง แก้ไข ยกเลิก
                                ระบบบริหารจัดการ/โปรแกรมออนไลน์
                                ระบบบัญชีผู้บริหารงานจัดการออนไลน์ และระบบบริหารจัดการศูนย์ทุกรูปแบบ โดยไม่จำเป็นต้อง
                                แจ้งให้ผู้เปิดศูนย์ฯ ทราบล่วงหน้า และการที่ผู้เปิดศูนย์ฯ
                                ได้ใช้งานระบบที่ได้ทำการแก้ไขเรียบร้อย
                                แล้วนั้น ถือเป็นการยอมรับการแก้ไขในครั้งนั้นแล้ว</p>
                            <p><strong>ข้อ 14. การปรับปรุง หรือยกเลิกบริการ</strong></p>
                            <p style="text-indent: 1.5cm">บริษัทฯ ขอสวงนสิทธิ์ในการปรับปรุงหรือยกเลิกบริการ
                                หรือส่วนใดส่วนหนึ่งของบริการ ไม่ว่า
                                จะชั่วคราวหรือตลอดไปได้ตลอดเวลา โดยบริษัทฯ จะแจ้งล่วงหน้าให้ทราบหรือไม่ก็ได้แต่ทั้งนี้การ
                                ยกเลิก หรือการระงับบริการ หรือส่วนใดส่วนหนึ่งของบริษัท ย่อมไม่กระทบถึงหนี้ใดๆ
                                ที่ผู้เปิดศูนย์ฯ
                                ยังคงค้างชำระอยู่กับ บริษัทฯ รวมทั้งไม่เป็นการลบล้างสิทธิหรือความรับผิดใดๆ ที่บริษัทฯ
                                และผู้เปิด
                                ศูนย์ฯ มีอยู่หรือได้เกิดขึ้นก่อนวันที่มีผลของการยกเลิก หรือการระงับบริการ
                                หรือส่วนใดส่วนหนึ่งของ
                                บริการดังกล่าว</p>
                            <p><strong>ข้อ 15. ความผิดพลาดที่เกิดจากระบบ</strong></p>
                            <p style="text-indent: 1.5cm">หากเกิดความผิดพลาด ล่าช้า ไม่ว่าจะเกิดขึ้นจากระบบคอมพิวเตอร์
                                ระบบอินเตอร์เน็ต
                                อุปกรณ์อิเล็กทรอนิกส์ต่างๆ ระบบแพลตฟอร์มของทางบริษัทฯ ระบบขนส่ง หรือระบบอื่นใดที่
                                เกี่ยวข้องกับการให้บริการ ไม่ว่าจะเกิดเหตุใดๆ ผู้เปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป
                                เซอร์วิส/
                                เอ็นบีเอ วันสต๊อป ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ
                                ตกลงจะไม่ยกเอาเหตุขัดข้องดังกล่าวมาเป็นข้อเรียกร้องค่าเสียหายใดๆ จากบริษัทฯ</p>
                            <p><strong>ข้อ 16. ค่าภาษีอากร และค่าธรรมเนียม</strong></p>
                            <p style="text-indent: 1.5cm">ค่าภาษีอากร และค่าธรรมเนียมจากการจ่ายค่าตอบแทน ในรูปแบบต่างๆ
                                อาธิเช่น
                                ค่าคอมมิชชั่น หรือเป็นเงินรางวัล หรือผลตอบแทนอื่นใด ที่ผู้เปิดศูนย์บริการลูกค้า เอ็นบีเอ
                                วันสต๊อป
                                เซอร์วิส/ เอ็นบีเอ วันสต๊อป ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและ
                                บริการ ได้รับในแต่ละ วัน/สัปดาห์/เดือน หรือ ณ เวลาอื่นใด ผู้เปิดศูนย์ฯ
                                เป็นผู้รับผิดชอบในภาษี
                                และค่าธรรมเนียมที่เกิดขึ้น เพื่อชำระให้แก่สรรพากร และบริษัทฯ</p>
                            <p><strong>ข้อ 17. แผนการตลาด</strong></p>
                            <p style="text-indent: 1.5cm">
                                การบริหารการจัดการตลาดผ่านสื่อสังคมออนไลน์เพื่อประชาสัมพันธ์บริการลูกค้า
                                บริษัท เอ็นบีเอ ดิจิตอล เซอร์วิส เซ็นเตอร์ จำกัด จะเป็นผู้จัดทำแผนการตลาดทั้งหมด
                                ไม่ว่าจะเป็น
                                การส่งเสริมการตลาด การโฆษณา สื่อสิ่งพิมพ์เนื้อหาการโฆษณา และการส่งเสริมการขาย ให้ขึ้นตรง
                                ต่อบริษัท เอ็นบีเอ ดิจิตอล เซอร์วิส เซ็นเตอร์ จำกัด ทั้งสิ้น ทางผู้เปิดศูนย์ฯ ไม่สามารถ
                                ดัดแปลง
                                เปลี่ยนแปลง เพิ่มเติม นอกเหนือจากที่ทางบริษัทฯ กำหนดไว</p>
                            <p><strong>ข้อ 18. ต้นฉบับของสัญญาอิเล็กทรอนิกส์</strong></p>
                            <p style="text-indent: 1.5cm">
                                ต้นฉบับของสัญญาอิเล็กทรอนิกส์จะถูกจัดเก็บไว้ภายในระบบของผู้เข้าใช้งาน โดยเปิดศูนย์ฯ
                                สามารถเข้าสู่ระบบเพื่อเรียกดูต้นฉบับได้ตลอดเวลา และภายหลังจากที่ได้ทำสัญญาฉบับนี้จะไม่
                                สามารถ แก้ไข เปลี่ยนแปลงเพิ่มเติมข้อความภายในสัญญาได้อีก
                            </p>
                            <p><strong>ข้อ 19. ระยะเวลาของสัญญา</strong></p>
                            <p style="text-indent: 1.5cm">ระยะเวลาของสิทธิ์การเปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป
                                เซอร์วิส/ เอ็นบีเอ วันสต๊อปช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์
                                ฝากขายสินค้าและบริการ ฉบับนี้คือ ไม่มี
                                กำหนดระยะเวลา จนกว่าจะมีประกาศเปลี่ยนแปลงเป็นลายลักษณ์อักษร
                            </p>
                            <p><strong>ข้อ 20. ค่าตอบแทน</strong></p>
                            <p style="text-indent: 1.5cm">ค่าตอบแทนการเปิดศูนย์ฯ และสมาชิก ภายในแบรนด์ NBA DIGITAL WORLDS
                                และ PLATFORM ของบริษัทฯ ทุกบประเภท บริษัทฯ มีสิทธิ์ในการเปลี่ยนแปลงหรือยกเลิกแผนการตลาด
                                และอัตราการจ่ายค่าตอบแทน โดยไม่ต้องบอกกล่าวล่วงหน้า</p>
                            <p><strong>ข้อ 21. การปฏิเสธความรับผิด</strong></p>
                            <p style="text-indent: 1.5cm">บริษัทฯ เป็นเพียงตัวแทนการชำระเงิน และบริษัทฯ
                                ไม่ทราบถึงข้อตกลงที่ทำขึ้น หรือสิทธิข้อ
                                ผูกพันใดๆ ระหว่าง ผู้บริโภค และผูกเรียกเก็บเงิน บริษัทฯ ไม่ต้องรับผิดชอบสำหรับความเสียหายที่
                                เกิดขึ้นจากการละเมินข้อตกลงระหว่างผู้บริโภคกับผู้เรียกเก็บเงิน ผู้เปิดศูนย์บริการลูกค้า
                                เอ็นบีเอ วันสต๊อป เซอร์วิส/ เอ็นบีเอ วันสต๊อป ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ
                                พาร์ทเนอร์ ฝากขาย
                                สินค้าและบริการ รับทราบ และตกลงว่าเป็นความรับผิดชอบของผู้เปิดศูนย์ฯ แต่เพียงผู้เดียวในการทำ
                                ให้แน่ใจว่าคำสั่งการชำระของผู้บริโภคถูกต้องก่อนที่จะมีคำสั่งให้ทางบริษัทฯ ทำการจ่ายเงิน
                                บริษัทฯ จะไม่รับผิดชอบในความรับผิด หรือค่าใช้จ่ายจากการชำระเกินกำหนดเวลาที่เกิดขึ้น
                            </p>
                            <p><strong>ข้อ 22. ข้อจำกัดความรับผิดของบริษัทฯ</strong></p>
                            <p style="text-indent: 1.5cm">ผู้เปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป เซอร์วิส/ เอ็นบีเอ
                                วันสต๊อป ช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ รับทราบ
                                และตกลงว่าบริษัทฯ ไม่ต้องมี
                                ความรับผิดหากบริษัทฯ ไม่สามารถทำให้คำสั่งการชำระเงิน เสร็จสิ้นได้ด้วยเหตุนอกเหนือจากการ
                                ควบคุมของบริษัทฯ ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะกรณีต่อไปน</p>
                            <p style="text-indent: 1.5cm">(ก) หากบัญชีในแพลตฟอร์มของผู้เปิดศูนย์ฯ มีเงินไม่เพียงพอ
                                โดยไม่ใช่ความผิดของบริษัทฯ</p>
                            <p style="text-indent: 1.5cm">(ข) ศูนย์กลางการประมวลผลการชำระเงินของบริษัทฯ ทำงานไม่ปกติ
                                และผู้เปิดศูนย์ฯ รู้หรือ
                                ได้รับแจ้งจากทางบริษัทฯ เกี่ยวกับการทำงานที่ผิดปกตินั้นก่อนที่ผู้เปิดศูนย์ฯ ดำเนินการรับโอน
                                ดังกล่าว</p>
                            <p style="text-indent: 1.5cm">(ค) ผู้เปิดศูนย์ฯ ไม่ได้ให้ข้อมูลที่ถูกต้องแก่ทางบริษัทฯ
                                หรือข้อมูลที่ผู้เปิดศูนย์ฯ ให้ไว้กลายเป็น
                                ข้อมูลที่ไม่ถูกต้อง</p>
                            <p style="text-indent: 1.5cm">(ง) พฤติการณ์นอกเหนือการควบคุมของบริษัทฯ เหตุสุดวิสัย
                                หรือไม่ว่าจะสามารถคาดเห็นได้หรือคาดเห็นแล้วก็ตาม อันมีลักษณะเป็นการขัดขวางการดำเนินการโอน
                                และบริษัทฯ ได้เตรียมการ
                                ป้องกันไว้ตามความสมควรเพื่อหลีกเลี่ยงพฤติการณ์ดังกล่าว
                            </p>
                            <p><strong>ข้อ 23. การยกเลิก การเปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป เซอร์วิส/ เอ็นบีเอ
                                    วันสต๊อปช็อป/เอ็นบีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ </strong>
                            </p>
                            <p style="text-indent: 1.5cm">บริษัทฯ มีสิทธิ
                                ยกเลิกสถานภาพการเปิดศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป เซอร์วิส/ เอ็นบีเอ วันสต๊อป
                                ช็อป/เอ็น
                                บีเอ สมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ โดยมีเงื่อนไข ดังน</p>
                            <p style="text-indent: 1.5cm">23.1. ผู้เปิดศูนย์ฯ กระทำผิดสัญญาการเปิดศูนย์ฯ ข้อใดข้อหนึ่ง
                                ไม่ว่าจะทั้งหมด หรือแต่
                                บางส่วน</p>
                            <p style="text-indent: 1.5cm">23.2. ผู้เปิดศูนย์ฯ ไม่ประสงค์ดำเนินการ
                                หรือต้องการยกเลิกสถานภาพการเปิดศูนย์ฯ
                                โดยสามารถยื่นคำร้องเป็นลายลักษณ์อักษร เพื่อให้บริษัทฯ ทำการอนุมัติยกเลิกสัญญาการเปิดศูนย์ฯ
                                และ หากว่าผู้เปิดศูนย์ฯ ต้องการขอคืนเงินในระบบแพลตฟอร์ม บริษัทฯ ขอวงวนสิทธิ์ไม่คืนเงินใน
                                ระบบทุกกรณีแต่สามารถใช้บริการจนกว่าเงินจะหมดได้ </p>
                            <p style="text-indent: 1.5cm">23.3. ผู้เปิดศูนย์ฯ จะต้องทำตามหลักเกณฑ์ และวิธีการตามบริษัทฯ
                                กำหนดเท่านั้น บริษัทฯ ขอสงวนสิทธิ์ในกรณีที่ศูนย์บริการลูกค้า เอ็นบีเอ วันสต๊อป เซอร์วิส/
                                เอ็นบีเอ วันสต๊อป
                                ช็อป/เอ็นบีเอสมาร์แพลตฟอร์ม/เอ็นบีเอ พาร์ทเนอร์ ฝากขายสินค้าและบริการ ของผู้เปิดศูนย์ฯ
                                ผิดเงื่อนไขบาง
                                ประการกับทางบริษัทฯ ผู้เปิดศูนย์ฯ ไม่สามารถดำเนินการยกเลิกบัญชีด้วยตนเองได</p>
                            <p style="text-indent: 1.5cm">23.4. ในกรณีที่ผู้เปิดศูนย์ฯ ไม่ดำเนินการชำระค่าบริหารจัดการ
                                รายเดือน รายปีหรือเงินอื่น
                                ใด ที่บริษัทฯ จะกำหนดในอนาคต เป็นลายลักษณ์อักษร บริษัทฯ มีสิทธิที่จะระงับการให้บริการทันที
                                หากผู้เปิดศูนย์ไม่ยอมรับในเงื่อนไขดังกล่าว
                            </p>
                            <p style="text-indent: 1.5cm;">23.5. ผู้เปิดศูนย์ฯ เกิดปัญหา ข้อพิพาท
                                ถูกหน่วยงานของรัฐมีคำสั่งให้ยึด หรืออายัดทรัพย์สิน
                                หรือบุคคลที่ถูกกำหนด หรือถูกกล่าวหา หรือถูกสงสัยว่าเป็นผู้กระทำความผิด หรือกระทำการใดๆ
                                ที่อาจก่อให้เกิดความเสียหายกับบริษัทฯ หรือบุคคลอื่นใด แล้วแต่กรณ</p>
                            <p style="text-indent: 1.5cm;">23.6. ใบริษัทฯ ขอสวงวนสิทธิ์ไม่คืนเงินในทุกกรณี
                                ไม่ว่าจะเป็นเงินที่เหลือในระบบแพลตฟอร์ม
                                หรือ เงินที่ได้ชำระค่าบริการศูนย์ หรือเงินส่วนต่างใดๆ ที่ทางผู้เปิดศูนย์ฯ
                                มีหน้าที่ถอนการติดตั้ง
                                ทั้งหมดที่เกี่ยวข้องกับทาง บริษัทฯ ไม่ว่าจะเป็น ป้ายไวนิล โปสเตอร์ โบรชัวร์ หรือสิ่งอื่นใด
                                ที่บ่งบอก
                                หรือเกี่ยวเนื่องกับทางบริษัทฯ โดยจะต้องดำเนินการถอนการติดตั้งให้แล้วเสร็จภายใน 7 วัน
                                นับวันที่
                                เลิกสัญญา และไม่ดำเนินการอื่นใดอันเป็นการแสดงว่าเกี่ยวข้อง หรือเป็นตัวแทนของทางบริษัทฯ อนึ่ง
                                การสิ้นสุดระยะเวลาของสัญญา หรือการบอกเลิกสัญญาฉบับนี้ไม่ว่าด้วยเหตุใด มิให้กระทบต่อความ
                                สมบูรณ์ของข้อกำหนดในสัญญาฉบับนี้ระบุไว้ว่าให้มีผลบังคับใช้เมื่อสัญญาสิ้นสุดลง และมิให้เสื่อม
                                สิทธิหรือสิทธิเรียกร้องความเสียหายอื่นใดที่คู่สัญญามีอยู่
                            </p>
                            <p><strong>ข้อ 24. กฎหมายที่บังคับใช้</strong></p>
                            <p style="text-indent: 1.5cm;">สัญญาอิเล็กทรอนิกส์ฉบับนี้อยู่ภายใต้บังคับกฎหมายไทย</p>
                            <p style="text-indent: 1.5cm;">สัญญานี้ทำขึ้นสองฉบับมีข้อความถูกต้อง
                                ตรงกันคู่สัญญาแต่ละฝ่ายได้อ่านโดยตลอดแล้วยืนยันว่าถูกต้องตามเจตนาจึงได้ลงลายมือชื่อไว้เป็น
                                สำคัญต่อหน้าพยาน และแต่ละฝ่ายยึดถือไว้ฝ่ายหนึ่งฉบับ</p>

                            <br />
                            <p><em><strong>หมายเหตุ : </strong> การเข้าสู่ระบบโดยใช้ชื่อผู้ใช้ (username) และ รหัสผ่าน
                                    (password) เข้าสู่ระบบและมี
                                    การยอมรับเงื่อนไขและข้อตกลงในระบบ ถือว่าสัญญาฉบับนี้สมบูรณ์แบบ</em></p>

                        </div>
                    </div>
                </div>
            </ScrollPanel>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import 'dayjs/locale/th'
export default {
    props: {
        partner: Object
    },
    methods: {
        getDay(date) {
            return dayjs(date).locale('th').format('DD')
        },
        getMonth(date) {
            return dayjs(date).locale('th').format('MMMM')
        },
        getYear(date) {
            return dayjs(date).locale('th').add(543, 'year').format('YYYY')
        },
        print() {
            const divContents = document.getElementById("printMe").innerHTML;
            const a = window.open();
            const css = `<style>
        *{font-family:"Sarabun";}
        .page{width: 210mm; padding:1.5cm}
        .text-center{text-align: center}
        p { margin: 0px;line-height: 1.8 ;text-align: justify; text-justify: inter-word; }
        .mark { color: darkblue }
            </style>`;
            a.document.write('<html>');
            a.document.write("<link href='https://fonts.googleapis.com/css?family=Sarabun' rel='stylesheet'>")
            a.document.write(css)
            a.document.write(`<title>สัญญาอิเล็กทรอนิกส์ คุณ ${this.partner.partner_name}</title>`)
            a.document.write('<body>');
            a.document.write('<div class="page">');
            a.document.write(divContents);
            a.document.write('</div>')
            a.document.write('</body></html>');
            a.document.close();
            setTimeout(() => {
                a.print();
                a.close();
            }, 2500)
        },
    }
}
</script>

<style scoped>
p {
    margin: 0px;
    line-height: 1.8;
    text-align: justify;
    text-justify: inter-word;
}

.mark {
    color: darkblue
}
</style>