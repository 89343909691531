<template >
  <div class="grid px-2 py-2" style="background: radial-gradient(#e6bbd7, #9041f0);">
    <div class="col ">
      <Button @click="openSideber()" class="button-menu ">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g> <!-- Use a dynamic fill property -->
            <path
              d="M19 18c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1s.45-1 1-1h14m0-2H5c-1.654 0-3 1.346-3 3s1.346 3 3 3h14c1.654 0 3-1.346 3-3s-1.346-3-3-3zm0-5c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1s.45-1 1-1h14m0-2H5c-1.654 0-3 1.346-3 3s1.346 3 3 3h14c1.654 0 3-1.346 3-3s-1.346-3-3-3zm0-5c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1s.45-1 1-1h14m0-2H5C3.346 2 2 3.346 2 5s1.346 3 3 3h14c1.654 0 3-1.346 3-3s-1.346-3-3-3z" />
          </g>
        </svg>
        <span class="button__text ml-2">เมนู</span>
      </Button>
    </div>
    <div class="flex-grow-0 ">
      <Menubar :model="item_menu"
        class="custom-menu border-round-3xl h-3rem border-2 border-purple-500 hover:border-2 hover:border-purple-900 flex align-items-center justify-content-center  bg-white  ">
      </Menubar>
    </div>
    <div class="col flex align-items-center  justify-content-end">
      <Button class="button-logout " @click="logout()">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g> <!-- Use a dynamic fill property -->
            <path d="M13 4.009a1 1 0 1 0-2 0l-.003 8.003a1 1 0 0 0 2 0L13 4.01Z" />
            <path
              d="M4 12.992c0-2.21.895-4.21 2.343-5.657l1.414 1.414a6 6 0 1 0 8.485 0l1.415-1.414A8 8 0 1 1 4 12.992Z" />
          </g>
        </svg>
        <span class="button__text ml-2">ออกจากระบบ</span>
      </Button>
    </div>
  </div>

  <div class="grid " v-if="textSlide !== ''" style="background: linear-gradient(to right, #a18cd1, #fbc2eb);">
    <div class="col-12">
      <marquee-text class="text-slide border-round-3xl" :repeat="3">{{ textSlide }}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</marquee-text>
    </div>
  </div>

  <Sidebar v-model:visible="sidebar">
    <div class="grid">
      <div class="col-12 text-center">
        <img alt="logo" src="@/assets/one_stop_logo.png" height="100" class="mr-2" />
      </div>
    </div>
    <PanelMenu :model="item_sidebar"></PanelMenu>
  </Sidebar>

  <!-- Dialog Block-->
  <Dialog v-model:visible="dialogBlock" :closable="false" :modal="true" :draggable="false"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '20vw' }">
    <div class="grid">
      <div class="col-12 text-center">
        <i class="pi pi-lock" style="font-size: 4rem"></i>
      </div>
      <div class="col-12 text-center">
        <h2>ร้านค้าถูกระงับ!</h2>
        <p class="m-0 p-0 mt-3">ร้านค้าถูกระบบชั่วคราว กรุณาติดต่อส่วนกลาง</p>
      </div>
    </div>
    <template #footer>
      <Button label="ออกจากระบบ" class="p-button-danger w-full" @click="logout()" />
    </template>
  </Dialog>
</template>

<script>
import axios from 'axios';
import Menubar from "primevue/menubar";
import MarqueeText from "vue-marquee-text-component";
export default {
  components: {
    Menubar,
    MarqueeText
  },
  async mounted() {
    await axios.get(`${process.env.VUE_APP_SHOP}shop/${localStorage.getItem('shop_id')}`, {
      headers: {
        'auth-token': `Bearer ${localStorage.getItem('token')}`
      }
    }).then((res) => {
      this.shop = res.data.data;
      if (!res.data.data.shop_status) {
        this.dialogBlock = true;
      }
    })

    await axios.get(`${process.env.VUE_APP_SHOP}more/function_more/function/declare_text_slide`, {
      headers: {
        'auth-token': `Bearer ${localStorage.getItem('token')}`
      }
    }).then((res) => {
      if (res.data.data.func_detail.length !== 0) {
        for (let i = 0; i < res.data.data.func_detail.length; i++) {
          this.textSlide = this.textSlide + ' | ' + res.data.data.func_detail[i]
        }
      }
    })
    this.setItemMenu();
    this.setItemSidebar();
  },
  data: () => ({
    shop: null,
    sidebar: false,
    dialogBlock: false,
    textSlide: "",
    item_menu: [],
    item_sidebar: [],
  }),
  methods: {
    checkPosition(position) {
      if (this.$store.getters.position === position) {
        return true;
      } else {
        return false;
      }
    },
    checkFunction(item, position) {
      const func = this.shop.shop_function.find((el) => el.name === item);
      if (func) {
        if (func.status) {
          if (position) {
            return this.checkPosition(position)
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    setItemSidebar() {
      this.item_sidebar = [{
        label: "เมนูหลัก",
        icon: 'pi pi-th-large',
        to: '/pos'
      },
      {
        label: "บริการ",
        items: [
          {
            label: "ขายสินค้า",
            to: "/pos/shop",
            icon: "pi pi-circle",
            visible: this.checkFunction('pos'),
          },
          {
            label: "เติมเงินมือถือ",
            to: "/pos/mobile_topup",
            icon: "pi pi-circle",
            visible: this.checkFunction('mobile_topup'),
          },
          {
            label: "ส่งพัสดุ",
            to: "/pos/express",
            icon: "pi pi-circle",
            visible: this.checkFunction('express'),
          }, {
            label: "ฝากจ่ายเคาน์เซอร์วิส",
            to: "/pos/counter_service",
            icon: "pi pi-circle",
            visible: this.checkFunction('counter_service'),
          },
          {
            label: "บริการอื่นๆ",
            to: "/pos/media_service",
            icon: "pi pi-circle",
            visible: this.checkFunction(''),
          },
          {
            label: "รับฝากโอน",
            to: "/pos/transfer",
            icon: "pi pi-circle",
            visible: this.checkFunction('transfer'),
          },

        ]
      },
      {
        label: "ประวัติ",
        items: [
          { label: "ประวัติการขายสินค้า", icon: "pi pi-circle", to: "/report/shop/history", visible: this.checkFunction('pos') },
          { label: "ประวัติการเติมเงินมือถือ", icon: "pi pi-circle", to: "/report/mobile_topup/history", visible: this.checkFunction('mobile_topup') },
          { label: "ประวัติการส่งพัสดุ", icon: "pi pi-circle", to: "/report/express/history", visible: this.checkFunction('express') },
          { label: "ประวัติฝากจ่ายเคาน์เตอร์เซอร์วิส", icon: "pi pi-circle", to: "/report/counter_service/history", visible: this.checkFunction('counter_service'), },
          { label: "ประวัติสั่งงานบริการอื่นๆ", icon: "pi pi-circle", to: "/report/media_service/history", },
        ]
      },]
    },
    setItemMenu() {
      this.item_menu = [{
        label: "หน้าแรก",
        icon: "pi pi-fw pi-home",
        to: "/",
      },
      {
        label: "ตะกร้าสินค้า",
        icon: "pi pi-fw pi-shopping-cart",
        visible: this.checkFunction('pos', 'manager'),
        items: [
          {
            label: "สั่งสินค้า",
            icon: "pi pi-fw pi-circle",
            items: [
              {
                label: "สินค้าซื้อด้วยเครดิต",
                to: "/cart/product"
              },
              {
                label: "สินค้าฝากขาย",
                to: "/cart/product/consignment"
              }
            ]
          },
          {
            label: "รายการสั่งซื้อ",
            icon: "pi pi-fw pi-circle",
            items: [
              {
                label: 'เครดิต',
                to: '/cart/order'
              },
              {
                label: 'ฝากขาย',
                to: '/cart/order/consignment'
              }
            ]
          },
        ],
      },
      {
        label: "สต๊อกสินค้า",
        icon: "pi pi-fw pi-database",
        visible: this.checkFunction('pos'),
        items: [
          {
            label: "สินค้าทั้งหมด",
            icon: "pi pi-fw pi-circle",
            to: "/product",
          },
          {
            label: "ปรับสต๊อกสินค้า",
            icon: "pi pi-fw pi-sync",
            to: "/product/change_stock",
          },
          {
            label: "นำเข้าสินค้า",
            icon: "pi pi-fw pi-download",
            to: '/product/import',
            visible: this.checkPosition('manager')
          }
        ],
      },
      {
        label: "เกี่ยวกับร้านค้า",
        icon: "pi pi-fw pi-briefcase",
        items: [
          {
            label: "ปิดขายประจำวัน",
            icon: 'pi pi-fw pi-times',
            to: "/shop/closeshop",
            visible: this.checkFunction('pos', 'manager'),
          },
          {
            label: "ใบแจ้งหนี้",
            icon: "pi pi-fw pi-file",
            to: "/shop/invoice",
            visible: this.checkFunction('pos', 'manager'),
          },
          {
            label: "จัดการพนักงาน",
            icon: "pi pi-fw pi-users",
            to: "/shop/employee",
          },
          {
            label: "สินค้าระบบขนส่ง",
            icon: "pi pi-fw pi-box",
            to: "/shop/product_express",
            visible: this.checkFunction('express', 'manager'),
          },
          {
            label: "เกี่ยวกับร้านค้า",
            icon: "pi pi-fw pi-info-circle",
            to: "/shop/about",
          },
        ],
      },
      {
        label: "ใบเสนอราคา",
        icon: "pi pi-envelope",
        to: "/report/tax_service/history",
      },
      {
        label: "ตั้งค่าโปรไฟล์",
        icon: "pi pi-fw pi-cog",
        to: "/profile",
      }]
    },
    openSideber() {
      this.sidebar = true;
    },
    logout() {
      this.$confirm.require({
        message: "ต้องการออกจากระบบนี้?",
        header: "ออกจากระบบ",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        acceptLabel: "ออกจากระบบ",
        rejectLabel: "ยกเลิก",
        accept: () => {
          localStorage.clear();
          window.location.reload();
        },
      });
    },
  },
};
</script>

<style scoped>
.text-slide {
  background-color: #35155D;
  border: 2px solid;
  color: #FEFF86;
  text-shadow: #A31ACB 1px 0 10px;
  border: #A31ACB 0.2em medium;

  font-size: 16px;
  padding-left: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
}
</style>

<style>
.custom-menu .p-menuitem-text {
  color: #3c076e !important;

}

.custom-menu .p-menubar .p-menubar-root-list>.p-menuitem>.p-menuitem-content .p-menuitem-link .p-menuitem-text {
  color: #3c076e !important;
}

a:hover {
  background-color: #ECF2FF;
  border-radius: 8px;
  color: #3c076e;

}
</style>

<!-- ปุ่มออกจากระบบ -->
<style scoped>
.button-logout {
  --main-focus: #2d8cf0;
  --font-color: #dedede;
  --bg-color-sub: #222;
  --bg-color: #8B1874;
  --main-color: #dedede;
  position: relative;
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  background-color: var(--bg-color);
  border-radius: 40px;
}

.button-logout .button__text {
  color: var(--font-color);
  font-weight: 600;
}

.button-logout:hover {
  background: var(--main-color) !important;
  color: var(--font-color);
  border: 2px solid var(--bg-color) !important;
  box-shadow: 4px 4px var(--bg-color);
}

.button-logout:hover .button__text {
  color: var(--bg-color);
}

.button-logout svg {
  fill: white;
  /* Set the default fill color to white */
}

.button-logout:hover svg {
  fill: var(--bg-color);
  /* Change the fill color to purple on hover */
}
</style>

<!-- ปุ่มเมนู -->
<style scoped>
.button-menu {
  --main-focus: #2d8cf0;
  --font-color: #dedede;
  --bg-color-sub: #222;
  --bg-color: #8B1874;

  --main-color: #dedede;
  position: relative;
  border: 2px solid var(--main-color);
  box-shadow: 4px 4px var(--main-color);
  background-color: var(--bg-color);
  border-radius: 20px;
}

.button-menu .button__text {
  color: white;
  font-weight: 600;
}

.button-menu:hover {
  background: var(--main-color) !important;
  color: var(--bg-color);
  border: 2px solid var(--bg-color) !important;
  box-shadow: 4px 4px var(--bg-color);
}

.button-menu:hover .button__text {
  color: var(--bg-color);
}

.button-menu svg {
  fill: white;
  /* Set the default fill color to white */
}

.button-menu:hover svg {
  fill: var(--bg-color);
  /* Change the fill color to purple on hover */
}

/* Add responsive styles for screens narrower than 412px */
@media (max-width: 412px) {
  .button-logout {
    /* Define your responsive styles here */
    font-size: 11px;
    /* Adjust the font size for smaller screens */
    padding: 10px 20px;
    /* Adjust padding for smaller screens */
  }
}
</style>
